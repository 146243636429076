import React, { Component } from 'react';

import './base.css';

import {getPortfolioPositionInfo, getPortfolioCompanyInfo, getPortfolioProjectsInfo} from '../lib/portfolioData'


import Header from '../components/header'
import Body from '../components/body'


class Base extends Component {

  constructor(props)
  {
    super(props);

    let companyInfo = getPortfolioCompanyInfo();
    let positionInfo = getPortfolioPositionInfo();
    let projectInfo = getPortfolioProjectsInfo();

    this.state =
    { 
      companyInfo: companyInfo,
      positionInfo: positionInfo,
      projectInfo: projectInfo,

      showDeployments: true,
    }

    this.onDocumentationClicked = this.onDocumentationClicked.bind(this);
    this.onDeploymentsClicked = this.onDeploymentsClicked.bind(this);
  }

  componentDidMount()
  { 

  }

  onDocumentationClicked()
  {
    if (this.state.showDeployments)
    {
      this.setState({showDeployments: false});
    }
  }


  onDeploymentsClicked()
  {
    if (!this.state.showDeployments)
    {
      this.setState({showDeployments: true});
    }
  }

 
  render() {
    return (
      <div id="base_template">

        <Header onDeploymentsCallback={this.onDeploymentsClicked}
                onDocumentationCallback={this.onDocumentationClicked}
                isDocumentationActive={!this.state.showDeployments}

        />
        
        <Body companyInfo={this.state.companyInfo} 
              positionInfo={this.state.positionInfo} 
              projectInfo={this.state.projectInfo} 
              showDeployments={this.state.showDeployments}
        />

      </div>
    );
  }
}

export default Base;
