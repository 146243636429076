// Header
//
//  desc:
//         
//
//
//  props:
//    onDeploymentsCallback:        callback function for when the deployments button is clicked
//    onDocumentationCallback:      callback function for when the documentatino button is clicked
//    isDocumentationActive:         bool indicating which button should be 'highlighted'  if true, docuemtnation, if false, deployments
//

import React, { Component } from 'react';

import { Row, Col} from 'reactstrap';

import './index.css';



class Header extends Component 
{
  
  constructor(props)
  {
    super(props)
    this.state = {

    };

    this.onDeploymentsClicked = this.onDeploymentsClicked.bind(this);
    this.onDocumentationClicked = this.onDocumentationClicked.bind(this);

  }


  onDocumentationClicked()
  {
    if (this.props.onDocumentationCallback)
    {
      this.props.onDocumentationCallback();
    }
  }


  onDeploymentsClicked()
  {
    if (this.props.onDeploymentsCallback)
    {
      this.props.onDeploymentsCallback();
    }
  }


  render() 
  {
    let documentsClass = 'header_button'
    let deploymentsClass = 'header_button'
    if (this.props.isDocumentationActive)
    {
      documentsClass = documentsClass + ' header_button_active'
    }
    else
    {
      deploymentsClass = deploymentsClass + ' header_button_active'
    }


    return (
      <div className="odieworks_header">
        
        <Row className='header_row'>
          <Col sm='1' className='header_spacer'>
          </Col>

          <Col sm='auto' className={documentsClass} onClick={this.onDocumentationClicked} >
            <Row className='header_button_row'>
              <Col sm='1'>
                <i className="far fa-list-alt"></i>
              </Col>
              <Col sm='8'>
                Documentation
              </Col>
            </Row>
          </Col>

          <Col sm='auto' className={deploymentsClass} onClick={this.onDeploymentsClicked} >
            <Row className='header_button_row'>
              <Col sm='1' className='header_button_col'>
                <i className="fas fa-rocket"></i>
              </Col>
              <Col sm='8' className='header_button_col'>
                Deployments
              </Col>
            </Row>
          </Col>

          <Col sm='7'>
          </Col>

        </Row>

      </div>
    );
  }
}
 
export default Header;
