// DocViewer
//
//  desc:
//         
//
//
//  props:
//

import React from 'react';
class DocViewer extends React.Component
{
 constructor(props)
  {
    super(props)
    this.state = {

    };
  }

  render()
  {
    return(
      <div> 
        <iframe src="https://erikwhutchinson.com/assets/Erik_Hutchinson_Resume.pdf#view=FitH&toolbar=0" width="100%" height="1280px"> </iframe> 
      </div>
    );
  }

}

export default DocViewer;
