// Background
//
//  desc:
//         
//
//
//  props:
//    companyInfo:      array of dictionaries corresponding to company info
//    positionInfo:     array of dictionaries corresponding to position info
//    projectInfo:      array of dictionaries corresponding to project info 
//    

import React from 'react';
import { Table, Row, Col, List } from 'reactstrap';

import './index.css';
import HistoryBox from '../HistoryBox'

class Background extends React.Component
{
 constructor(props)
  {
    super(props)
    this.state = {

    };
  }


  renderTableBody()
  {
    let rendered;

    //TODO: mainly a placeholder for now 
    rendered =  <tbody>
                  <tr>
                    <td className='custom_td'>
                      <HistoryBox />
                    </td>

                    <td className='custom_td'>
                      <HistoryBox />
                    </td>

                    <td className='custom_td'>
                      <HistoryBox />
                    </td>

                  </tr>
                </tbody>

    return rendered;
  }


  renderTableHead()
  {
    let rendered =  <thead>
                      <tr>
                        <th className='custom_th'>History</th>
                        <th className='custom_th'>Overview</th>
                        <th className='custom_th'>Information</th>
                      </tr>
                    </thead>
    return rendered;
  }


  renderTable()
  {
    let tableHead = this.renderTableHead();
    let tableBody = this.renderTableBody();

    let rendered = <Table>
                    {tableHead}
                    {tableBody}
                  </Table>

    return rendered;
  }


  getRandomTimeInterval() 
  {
    return Math.floor(Math.random() * Math.floor(7000)) + 1000;
  }

  renderProjectInfo()
  {
    let rendered; 

    let timerFunc = this.getRandomTimeInterval
    let projectInfoList = []
    this.props.projectInfo.forEach(function(info, index)
    {
      let subtitle = info.system
      let timerInterval = timerFunc()
      if (info.inProgress)
      {
        timerInterval = -1;
      }
      let projectCard = <HistoryBox title={info.project} 
                                    subtitle={subtitle}
                                    accessInfo={info.url}
                                    detailInfo={info.details} 
                                    spinTimer={timerInterval}
                                    />
      let key = info.project + "_" + index
      let renderedInfo = <li key={key}> {projectCard} </li>

      projectInfoList.push(renderedInfo);
    });

    rendered = <List type='unstyled'>{projectInfoList} </List>
    return rendered;
  }


  renderPositionInfo()
  {
    let rendered; 

    let timerFunc = this.getRandomTimeInterval
    let companyInfoList = []
    this.props.positionInfo.forEach(function(info, index)
    {
      let subtitle = info.companyName
      let timerInterval = timerFunc()
      if (info.inProgress)
      {
        timerInterval = -1;
      }
      let positionCard = <HistoryBox title={info.position} 
                                    subtitle={subtitle}
                                    accessInfo={info.url}
                                    detailInfo={info.details} 
                                    spinTimer={timerInterval}
                                    />
      let key = info.position + "_" + info.companyName + "_" + index
      let renderedInfo = <li key={key}> {positionCard} </li>
      companyInfoList.push(renderedInfo);
    });

    rendered = <List type='unstyled'>{companyInfoList} </List>

    return rendered;
  }


  renderCompanyInfo()
  {
    let rendered; 

    let companyInfoList = []
    let timerFunc = this.getRandomTimeInterval
    this.props.companyInfo.forEach(function(info, index)
    {
      let subtitle = info.location + "       " + info.duration
      let timerInterval = timerFunc()
      if (info.duration.includes("Present") || info.inProgress)
      {
        timerInterval = -1;
      }
      let companyCard = <HistoryBox title={info.companyName} 
                                    subtitle={subtitle}
                                    accessInfo={info.url}
                                    detailInfo={info.details} 
                                    spinTimer={timerInterval}
                                    />
      let key = info.companyName + "_" + index
      let renderedInfo = <li key={key}> {companyCard} </li>
      companyInfoList.push(renderedInfo);
    });

    rendered = <List type='unstyled'>{companyInfoList} </List>

    return rendered;
  }

  renderTitleBar()
  {
    return  <Row className='background_titlebar_row'>
              <Col sm='1' className='background_titlebar_icon_col background_titlebar_col'>
                <i className="fas fa-play background_titlebar_icon"></i>
              </Col>
              <Col sm='8' className='background_titlebar_title background_titlebar_icon_col'>
                Erik W Hutchinson's Deployments
              </Col>
            </Row>


  }


  renderColumnHeaders()
  {
    return  <Row className='background_headers'>
              <Col sm='4' >
                Experience
              </Col>
              <Col sm='4' >
                Positions
              </Col>
              <Col sm='4' >
                Systems/Products/Projects
              </Col>
            </Row>

  }


  renderColumns()
  {
    let rendered
    let renderedColumnHeaders = this.renderColumnHeaders();
    let renderedCompanyInfo = this.renderCompanyInfo();
    let renderedPositionInfo = this.renderPositionInfo();
    let renderedProjectInfo = this.renderProjectInfo();
    rendered =  <div className='background_info_columns' >
                  {renderedColumnHeaders}

                  <Row>
                    <Col sm='4' >
                      {renderedCompanyInfo}
                    </Col>
                    <Col sm='4' >
                      {renderedPositionInfo}
                    </Col>
                    <Col sm='4' >
                      {renderedProjectInfo}
                    </Col>
                  </Row>

                </div>


    return rendered;
  }



  render()
  {
    let table = this.renderTable();

    let columns = this.renderColumns();
    let titleBar = this.renderTitleBar();

    return(
      <div className='background_history_div'>
        {titleBar}
        {columns}

      </div>
    );
  }

}

export default Background;