// SessionCard
//
//  desc:
//         
//
//
//  props:
//

import React from 'react';
import { Row, Col } from 'reactstrap';
import './index.css';


class SessionCard extends React.Component
{
 constructor(props)
  {
    super(props)
    this.state = {

    };

    this.onLeaveButtonClicked = this.onLeaveButtonClicked.bind(this);
  }

  onLeaveButtonClicked()
  {
    window.open('https://erikwhutchinson.com/session_over/', "_self")
  }


  renderTimerInfo()
  {
    let rendered
    let icon = <i className="far fa-clock"></i>
    let timeLeftPostText = 'Ends in an hour'

    rendered =  <Row>
                  <Col sm='3'>

                  </Col>
                  <Col sm='1'>
                    {icon}
                  </Col>
                  <Col sm='5' className='session_time_left'>
                    {timeLeftPostText}
                  </Col>
                  <Col sm='3'>

                  </Col>
                </Row>

    return rendered 
  }


  renderLeaveButton()
  {
    return <div className='leave_button' onClick={this.onLeaveButtonClicked}> Leave Session </div>
  }


  render()
  {
    let timerInfo = this.renderTimerInfo();
    let leaveButton = this.renderLeaveButton();

    return(
      <div className='session_card'>
        {timerInfo}

        {leaveButton}


      </div>
    );
  }

}

export default SessionCard;