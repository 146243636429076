// Container
//
//  desc:
//         
//
//
//  props:
//    showDeployments:  (bool) if true, shows the deployment page. if false, shows the docuemntation page 
//

import React from 'react';
import './index.css';

import Background from '../Background'
import DocViewer from '../DocViewer'

class Container extends React.Component
{
 constructor(props)
  {
    super(props)
    this.state = {

    };
  }


  render()
  {
    let renderedComponent
    if (this.props.showDeployments)
    {
      renderedComponent = <Background {...this.props} />
    }
    else
    {
      renderedComponent = <DocViewer {...this.props} />

    }

    return(
      <div className='main_container'>
        {renderedComponent}
      </div>
    );
  }

}

export default Container;
