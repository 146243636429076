// HistoryBox
//
//  desc:
//         
//
//
//  props:
//    title:
//    subtitle:
//    accessInfo:
//    detailInfo:
//    spinTimer:      amount of time to keep the spinner going (in ms). If not provided, will spin forever
//

import React from 'react';
import { Row, Col, Card, CardImg, CardText, CardLink, CardBody, CardTitle, CardSubtitle } from 'reactstrap';

import './index.css';
import DetailsCard from '../DetailsCard';


class   HistoryBox  extends React.Component
{
 constructor(props)
  {
    super(props)
    this.state = {
      shouldSpin: true
    };

    this.onAccessClicked = this.onAccessClicked.bind(this);
    this.onDetailsClicked = this.onDetailsClicked.bind(this);
    this.timerElapsed = this.timerElapsed.bind(this);
    this.onClickOutsideDetails = this.onClickOutsideDetails.bind(this)

  }


  componentDidMount()
  {
    if (this.props.spinTimer && this.props.spinTimer != -1)
    {
      var intervalId = setInterval(this.timerElapsed, this.props.spinTimer);
      // store intervalId in the state so it can be accessed later:
      this.setState({intervalId: intervalId});
    }
  }


  componentWillUnmount() 
  {
      document.removeEventListener('mousedown', this.handleClickOutside);
      clearInterval(this.state.intervalId);

  }


  onAccessClicked()
  {
    //TODO: may want to have a card appear before opening a new page
    window.open(this.props.accessInfo, "_blank")
  }


  onDetailsClicked()
  {
    this.setState({shouldRenderDetails: true});
  }


  onClickOutsideDetails()
  {
    this.setState({shouldRenderDetails: false});

  }

  timerElapsed()
  {
     clearInterval(this.state.intervalId);
     this.setState({shouldSpin: false});     
  }


  renderDetailCard()
  {
    let rendered 

    if (this.state.shouldRenderDetails)
    { // wont bother rendering if they shouldnt be shown 
      rendered = <DetailsCard details={this.props.detailInfo} outsideClickedHandler={this.onClickOutsideDetails}/>
    }

    return rendered 
  }


  render()
  {
    let access
    if (this.props.accessInfo && this.props.accessInfo.length > 0)
    {
      access = <div className='cardButton' onClick={this.onAccessClicked} > ACCESS </div>
    }
    let details
    if (this.props.detailInfo && Object.keys(this.props.detailInfo).length > 0)
    {
      details = <div className='cardButton' onClick={this.onDetailsClicked} > DETAILS </div>
    }

    let detailCard = this.renderDetailCard();

    let cardIcon = <i className="fas fa-play card-body-icon"></i>
    if (this.state.shouldSpin)
    {
      cardIcon = <i className="fas fa-sync-alt fa-spin card-body-icon"></i>
    }

    return(
        
      <Card>
        <CardBody>

          <Row>
            <Col sm='1'>
              {cardIcon}
            </Col>

            <Col sm='10'>
              <CardTitle tag="h4">{this.props.title}</CardTitle>
              <CardSubtitle tag="h6" className="mb-2 text-muted">{this.props.subtitle}</CardSubtitle>
            </Col>
          </Row>

          <div className='historybox_separator' />

          <Row>
            <Col sm='6'/>
            <Col sm='3' className='cardButton_access_padding cardButton_top_padding'>
              {access}
            </Col>
            <Col sm='3' className='cardButton_details_padding cardButton_top_padding' >
              {details}
            </Col>
          </Row>

        </CardBody>
 
        {detailCard}

      </Card>
    
    );
  }

}

export default HistoryBox;
