import {endpointCallGetPositionInfo} from '../comms'

//will attempt to retrieve portfolio position info
export function getPortfolioPositionInfo() 
{ 
  //TODO: stubbed function  for now just returning data directly... in the future, retrieve data from aws lambdas

  let retData = [ 
    {
      position: 'Senior Software Engineer',
      companyName: 'PayTrace',
      url: 'https://paytrace.net/',
      duration: '2017 to Present',
      inProgress: true,
      details: {  project: 'PayTrace',
                  text:   [  "Developed SaaS for leading Fintech in B2B space." 
                            ,"Performed key role in migrating legacy technology to modern frameworks."
                            ,"Designed and implemented new outward facing portal stack."
                          ],
                 imageSrc: ['https://erikwhutchinson.com/assets/images/paytrace_logo.png',
                            ],
                  urls: [{url: 'https://paytrace.net/', 
                          alt: 'PayTrace'}]
                },
    },
    
    {
      position: 'Contract Software Engineer',
      companyName: 'Umpqa Bank',
      url: 'https://www.umpquabank.com/',
      duration: '2016 to 2017',
      details: {  project: 'Umpqua',
                  text:   [  "Built out treasury management portal for bank processing." 
                            ,"Worked directly with Treasury Management to meet customer requirements."
                            ,"Developed in multiple Javascript frameworks, C#, and Sql Server."
                          ],
                 imageSrc: [  'https://erikwhutchinson.com/assets/images/umpqua_bank_logo.png',
                            ],
                  urls: [{url: 'https://www.umpquabank.com/', 
                          alt: 'Umpqua Bank'}]
                },
    },
 
    {
      position: 'Lead Software Engineer',
      companyName: 'Prefrent Inc.',
      duration: '2015 to 2016',
      details: {  project: 'Prefrent',
                  text:   [  "Developed Ruby on Rails portal for large data correlation SaaS." 
                            ,"Led development team in Agile environment to iterate public API."
                            ,"Engineered AI data mining solution hosted in the cloud."
                          ],
                 imageSrc: [  'https://erikwhutchinson.com/assets/images/Prefrent_logo.jpg',
                            ],
                },
    },

    {
      position: 'Senior Software Engineer',
      companyName: 'Next IT / Verint',
      location: 'Spokane, WA',
      url: 'https://www.nextit.com/',
      duration: '2012 to 2015',
      details: {  project: 'NextIT',
                  text:   [  "Created mobile applications for the Army to aid in recruitment." 
                            ,"Developed a pilot that landed a $6.5 million contract with Merrill Lynch."
                            ,"Worked directly with the sales team to provide potential solutions to clients."
                          ],
                imageSrc: [  'https://erikwhutchinson.com/assets/images/askJenn_1.png',
                              'https://erikwhutchinson.com/assets/images/askJenn_2.png',
                              'https://erikwhutchinson.com/assets/images/askJenn_3.png',
                              'https://erikwhutchinson.com/assets/images/askSpike_1.png',
                              'https://erikwhutchinson.com/assets/images/askSpike_2.png',
                              'https://erikwhutchinson.com/assets/images/sgtStar_iPhone_1.png',
                              'https://erikwhutchinson.com/assets/images/sgtStar_iPhone_2.png',
                              'https://erikwhutchinson.com/assets/images/sgtStar_iPhone_3.png',
                              'https://erikwhutchinson.com/assets/images/sgtStar_web_1.png',
                              'https://erikwhutchinson.com/assets/images/sgtStar_web_2.png',
                            ],
                  urls: [{url: 'https://www.nextit.com/', 
                          alt: 'Next IT'}]
                },
    },

    {
      position: 'Senior Software Engineer',
      companyName: 'Telect / Amphenol',
      url: 'https://amphenol-ns.com/',
      details: {  project: 'Telect',
                  text:   [  "Developed the external facing e-commerce website Telect.com." 
                            ,"Built applications in the iPhone/iPad mobile space to branch out the brand."
                            ,"Developed in C/C++, Objective-C, Javascript, ASP.NET, and DotNetNuke."
                          ],
                 imageSrc: [  'https://erikwhutchinson.com/assets/images/telect_website_1.png',
                              'https://erikwhutchinson.com/assets/images/telect_website_5.png',
                              'https://erikwhutchinson.com/assets/images/telecom_tycoon_1.jpg',
                              'https://erikwhutchinson.com/assets/images/telect_website_3.png',
                              'https://erikwhutchinson.com/assets/images/telecom_tycoon_2.jpg',
                              'https://erikwhutchinson.com/assets/images/telect_website_4.png',
                              'https://erikwhutchinson.com/assets/images/telecom_tycoon_3.jpg',
                              'https://erikwhutchinson.com/assets/images/telect_website_2.png',
                              'https://erikwhutchinson.com/assets/images/telecom_tycoon_4.jpg',
                            ],
                  urls: [{url: 'https://amphenol-ns.com/', 
                          alt: 'Telect'}]
                },
    },

    {
      position: 'Application MacGyver ',
      companyName: 'Telect / Amphenol',
      url: 'https://amphenol-ns.com/',
      details: {  project: 'Telect',
                  text:   [  "Developed the external facing e-commerce website Telect.com." 
                            ,"Built applications in the iPhone/iPad mobile space to branch out the brand."
                            ,"Developed in C/C++, Objective-C, Javascript, ASP.NET, and DotNetNuke."
                          ],
                 imageSrc: [  'https://erikwhutchinson.com/assets/images/telect_website_1.png',
                              'https://erikwhutchinson.com/assets/images/telect_website_5.png',
                              'https://erikwhutchinson.com/assets/images/telecom_tycoon_1.jpg',
                              'https://erikwhutchinson.com/assets/images/telect_website_3.png',
                              'https://erikwhutchinson.com/assets/images/telecom_tycoon_2.jpg',
                              'https://erikwhutchinson.com/assets/images/telect_website_4.png',
                              'https://erikwhutchinson.com/assets/images/telecom_tycoon_3.jpg',
                              'https://erikwhutchinson.com/assets/images/telect_website_2.png',
                              'https://erikwhutchinson.com/assets/images/telecom_tycoon_4.jpg',
                            ],
                  urls: [{url: 'https://amphenol-ns.com/', 
                          alt: 'Telect'}]
                },
    },

    {
      position: 'Lead Software Engineer',
      companyName: 'Gourami Group',
      location: 'Spokane, WA',
      duration: '2009 to 2011',
      details: {  project: 'Gourami',
                  text:   [  "Worked with multiple clients to create mobile apps for the iPhone space." 
                            ,"Created Telecom Tycoon iPad app to increase Telect’s brand awareness."
                            ,"Integrated Social platforms, such as Facebook and Twitter, into mobile apps."
                          ],
                 imageSrc: [  'https://erikwhutchinson.com/assets/images/gouramiLogoNew.png',
                              'https://erikwhutchinson.com/assets/images/fanboy_1.png',
                              'https://erikwhutchinson.com/assets/images/fanboy_2.png',
                              'https://erikwhutchinson.com/assets/images/ibrawl_screensplash.jpg',
                              'https://erikwhutchinson.com/assets/images/ibrawl_screenfight.jpg',
                              'https://erikwhutchinson.com/assets/images/ibrawl_screenchallenge.jpg',
                              'https://erikwhutchinson.com/assets/images/top-chef-foodie-fight_1.jpg',
                              'https://erikwhutchinson.com/assets/images/top-chef-foodie-fight_2.jpg',
                              'https://erikwhutchinson.com/assets/images/top-chef-foodie-fight_3.jpg',
                              'https://erikwhutchinson.com/assets/images/top-chef-foodie-fight_4.jpg',
                         ],
                },
    },

    {
      position: 'Senior Software Engineer',
      companyName: 'Neokinetics Corp',
      location: 'Spokane, WA',
      duration: '2004 to 2008',
      details: {  project: 'Neokinetics',
                  text:   [ "Developed security system for eBay to identify users by typing patterns."
                            ,"Engineered cross-platform software: Mac OSX, Windows, Linux."
                            ,"Enterprise reporting development and data mining in MySQL for eBay."
                            ,"Built 3D data mining visualization tool using Crystal Space engine." 
                          ],
                 imageSrc: [  'https://erikwhutchinson.com/assets/images/devonthink_1.jpg',
                              'https://erikwhutchinson.com/assets/images/devonthink_2.png',
                              'https://erikwhutchinson.com/assets/images/devonthink_3.jpg',
                              'https://erikwhutchinson.com/assets/images/devonthink_4.jpg',
                              'https://erikwhutchinson.com/assets/images/ebay_1.png',
                              'https://erikwhutchinson.com/assets/images/ebay_2.png',
                              'https://erikwhutchinson.com/assets/images/source_code_example_1.png',
                              'https://erikwhutchinson.com/assets/images/source_code_example_2.png',
                              'https://erikwhutchinson.com/assets/images/source_code_example_3.png',
                         ],
                },
    },

    {
      position: 'Senior Software Engineer',
      companyName: 'Spectre AI / Next IT',
      location: 'Spokane, WA',
      url: 'https://amphenol-ns.com/',
      duration: '2003 to 2004',
      details: {  project: 'SpectreAI',
                  text:   [ "Programmed security tools for various government agencies."
                            ,"Developed client/server apps for government security communications."
                            ,"Built autonomous agents to sift large collections of data and send alerts."
                          ],
                 imageSrc: [  'https://erikwhutchinson.com/assets/images/askJenn_1.png',
                              'https://erikwhutchinson.com/assets/images/askJenn_2.png',
                              'https://erikwhutchinson.com/assets/images/askJenn_3.png',
                              'https://erikwhutchinson.com/assets/images/askSpike_1.png',
                              'https://erikwhutchinson.com/assets/images/askSpike_2.png',
                              'https://erikwhutchinson.com/assets/images/sgtStar_iPhone_1.png',
                              'https://erikwhutchinson.com/assets/images/sgtStar_iPhone_2.png',
                              'https://erikwhutchinson.com/assets/images/sgtStar_iPhone_3.png',
                              'https://erikwhutchinson.com/assets/images/sgtStar_web_1.png',
                              'https://erikwhutchinson.com/assets/images/sgtStar_web_2.png',
                            ],
                  urls: [{url: 'https://www.nextit.com/', 
                          alt: 'Spectre AI / Next IT'}]
                },
    },

    {
      position: 'Lead Software Engineer',
      companyName: 'Sennari Interactive',
      location: 'Los Angeles, CA',
      duration: '2002',
      details: {  project: 'Sennari',
                  text:   [ "Programmer for multiple titles for the Game Boy Advance and GameCube"
                            ,"Designed and planned out gameplay specifics for console sports titles."
                            ,"Engineer on team that developed first commercial mobile 3D graphics engine"
                            ,"Led programming team in development of Game Boy Advance sports title."
                          ],
                 imageSrc: [  'https://erikwhutchinson.com/assets/images/Driver2_box.jpg',
                              'https://erikwhutchinson.com/assets/images/DriverSequence.gif',
                              'https://erikwhutchinson.com/assets/images/driver2_2.jpg',
                              'https://erikwhutchinson.com/assets/images/driver2_3.jpg',
                              'https://erikwhutchinson.com/assets/images/driver2_4.jpg',
                              'https://erikwhutchinson.com/assets/images/ppg1.jpg',
                              'https://erikwhutchinson.com/assets/images/ppg2.jpg',
                              'https://erikwhutchinson.com/assets/images/ppg3.jpg',
                              'https://erikwhutchinson.com/assets/images/ppg4.jpg',
                            ],
                },
    },

    {
      position: 'Lead Software Engineer',
      companyName: 'Interactive Imagination',
      location: 'Seattle, WA',
      duration: '2000 to 2001',
      details: {  project: 'II',
                  text:   [ "Programmer for two published titles: Magi-Nation and Keeper’s Quest."
                            ,"Developed interactive game engines in Assembly for the Game Boy Color."
                            ,"Led programming team in development of Keeper’s Quest game."
                          ],
                 imageSrc: [  'https://erikwhutchinson.com/assets/images/mnd_box.jpg',
                              'https://erikwhutchinson.com/assets/images/keeper_1.gif',
                              'https://erikwhutchinson.com/assets/images/keeper_2.gif',
                              'https://erikwhutchinson.com/assets/images/mnkq_box.png',
                              'https://erikwhutchinson.com/assets/images/magination_2.gif',
                              'https://erikwhutchinson.com/assets/images/magination_4.gif',
                              'https://erikwhutchinson.com/assets/images/magination_5.gif',
                              'https://erikwhutchinson.com/assets/images/magi_nation1.jpg',
                              'https://erikwhutchinson.com/assets/images/assembly_source_example_1.png',
                              'https://erikwhutchinson.com/assets/images/assembly_source_example_1.png',                              
                            ],
                },
    }, 
 
    {
      position: 'Virtual Reality Developer',
      companyName: 'NIOSH Spokane Research Labs',
      location: 'Spokane, WA',
      duration: '1999 to 2000',
      details: {  project: 'NIOSH',
                  text:   [ "Developed graphical simulator to assist in employee safety training."
                            ,"Researched newest virtual reality technology and multi-user capabilities."
                            ,"Programmed in C++, TCL\TK, and DirectX under Windows."
                          ],
                 imageSrc: [  'https://erikwhutchinson.com/assets/images/Mine_Simulator.jpg',
                              'https://erikwhutchinson.com/assets/images/mine_sim1.jpg',
                              'https://erikwhutchinson.com/assets/images/mine_sim2.jpg',
                              'https://erikwhutchinson.com/assets/images/mine_sim3.jpg',
                            ],
                },
    },

   {
      position: 'Programmer Analyst',
      companyName: 'NIOSH Spokane Research Labs',
      location: 'Spokane, WA',
      duration: '1999 to 2000',
      details: {  project: 'NIOSH',
                  text:   [ "Developed graphical simulator to assist in employee safety training."
                            ,"Researched newest virtual reality technology and multi-user capabilities."
                            ,"Programmed in C++, TCL\TK, and DirectX under Windows."
                          ],
                 imageSrc: [  'https://erikwhutchinson.com/assets/images/Mine_Simulator.jpg',
                              'https://erikwhutchinson.com/assets/images/mine_sim1.jpg',
                              'https://erikwhutchinson.com/assets/images/mine_sim2.jpg',
                              'https://erikwhutchinson.com/assets/images/mine_sim3.jpg',
                            ],
                },
    },


  ];

  return retData;
}


export function getPortfolioCompanyInfo()
{
  let retData = [ 
    {
      companyName: 'PayTrace',
      location: 'Spokane, WA',
      url: 'https://paytrace.net/',
      duration: '2017 to Present',
      details: {  project: 'PayTrace',
                  text:   [  "Developed SaaS for leading Fintech in B2B space." 
                            ,"Performed key role in migrating legacy technology to modern frameworks."
                            ,"Designed and implemented new outward facing portal stack."
                          ],
                 imageSrc: ['https://erikwhutchinson.com/assets/images/paytrace_logo.png',
                            ],
                  urls: [{url: 'https://paytrace.net/', 
                          alt: 'PayTrace'}]
                },
    },

    {
      companyName: 'Umpqa Bank',
      location: 'Spokane, WA',
      url: 'https://www.umpquabank.com/',
      duration: '2016 to 2017',
      details: {  project: 'Umpqua',
                  text:   [  "Built out treasury management portal for bank processing." 
                            ,"Worked directly with Treasury Management to meet customer requirements."
                            ,"Developed in multiple Javascript frameworks, C#, and Sql Server."
                          ],
                 imageSrc: [  'https://erikwhutchinson.com/assets/images/umpqua_bank_logo.png',
                            ],
                  urls: [{url: 'https://www.umpquabank.com/', 
                          alt: 'Umpqua Bank'}]
                },
    },
 
    {
      companyName: 'Prefrent Inc.',
      location: "Coeur d'Alene, ID",
      duration: '2015 to 2016',
      details: {  project: 'Prefrent',
                  text:   [  "Developed Ruby on Rails portal for large data correlation SaaS." 
                            ,"Led development team in Agile environment to iterate public API."
                            ,"Engineered AI data mining solution hosted in the cloud."
                          ],
                 imageSrc: [  'https://erikwhutchinson.com/assets/images/Prefrent_logo.jpg',
                            ],
                },
    },

    {
      companyName: 'Next IT / Verint',
      location: 'Spokane, WA',
      url: 'https://www.nextit.com/',
      duration: '2012 to 2015',
      details: {  project: 'NextIT',
                  text:   [  "Created mobile applications for the Army to aid in recruitment." 
                            ,"Developed a pilot that landed a $6.5 million contract with Merrill Lynch."
                            ,"Worked directly with the sales team to provide potential solutions to clients."
                          ],
                 imageSrc: [  'https://erikwhutchinson.com/assets/images/askJenn_1.png',
                              'https://erikwhutchinson.com/assets/images/askJenn_2.png',
                              'https://erikwhutchinson.com/assets/images/askJenn_3.png',
                              'https://erikwhutchinson.com/assets/images/askSpike_1.png',
                              'https://erikwhutchinson.com/assets/images/askSpike_2.png',
                              'https://erikwhutchinson.com/assets/images/sgtStar_iPhone_1.png',
                              'https://erikwhutchinson.com/assets/images/sgtStar_iPhone_2.png',
                              'https://erikwhutchinson.com/assets/images/sgtStar_iPhone_3.png',
                              'https://erikwhutchinson.com/assets/images/sgtStar_web_1.png',
                              'https://erikwhutchinson.com/assets/images/sgtStar_web_2.png',
                            ],
                  urls: [{url: 'https://www.nextit.com/', 
                          alt: 'Next IT'}]
                },
    },

    {
      companyName: 'Telect / Amphenol',
      location: 'Spokane, WA',
      url: 'https://amphenol-ns.com/',
      duration: '2011 to 2012',
      details: {  project: 'Telect',
                  text:   [  "Developed the external facing e-commerce website Telect.com." 
                            ,"Built applications in the iPhone/iPad mobile space to branch out the brand."
                            ,"Developed in C/C++, Objective-C, Javascript, ASP.NET, and DotNetNuke."
                          ],
                 imageSrc: [  'https://erikwhutchinson.com/assets/images/telect_website_1.png',
                              'https://erikwhutchinson.com/assets/images/telect_website_5.png',
                              'https://erikwhutchinson.com/assets/images/telecom_tycoon_1.jpg',
                              'https://erikwhutchinson.com/assets/images/telect_website_3.png',
                              'https://erikwhutchinson.com/assets/images/telecom_tycoon_2.jpg',
                              'https://erikwhutchinson.com/assets/images/telect_website_4.png',
                              'https://erikwhutchinson.com/assets/images/telecom_tycoon_3.jpg',
                              'https://erikwhutchinson.com/assets/images/telect_website_2.png',
                              'https://erikwhutchinson.com/assets/images/telecom_tycoon_4.jpg',
                            ],
                  urls: [{url: 'https://amphenol-ns.com/', 
                          alt: 'Telect / Amphenol'}]
                },
    },
 
    {
      companyName: 'Gourami Group',
      location: 'Spokane, WA',
      duration: '2009 to 2011',
      details: {  project: 'Gourami',
                  text:   [  "Worked with multiple clients to create mobile apps for the iPhone space." 
                            ,"Created Telecom Tycoon iPad app to increase Telect’s brand awareness."
                            ,"Integrated Social platforms, such as Facebook and Twitter, into mobile apps."
                          ],
                 imageSrc: [  'https://erikwhutchinson.com/assets/images/gouramiLogoNew.png',
                              'https://erikwhutchinson.com/assets/images/fanboy_1.png',
                              'https://erikwhutchinson.com/assets/images/fanboy_2.png',
                              'https://erikwhutchinson.com/assets/images/ibrawl_screensplash.jpg',
                              'https://erikwhutchinson.com/assets/images/ibrawl_screenfight.jpg',
                              'https://erikwhutchinson.com/assets/images/ibrawl_screenchallenge.jpg',
                              'https://erikwhutchinson.com/assets/images/top-chef-foodie-fight_1.jpg',
                              'https://erikwhutchinson.com/assets/images/top-chef-foodie-fight_2.jpg',
                              'https://erikwhutchinson.com/assets/images/top-chef-foodie-fight_3.jpg',
                              'https://erikwhutchinson.com/assets/images/top-chef-foodie-fight_4.jpg',
                         ],
                },
    },

    {
      companyName: 'Neokinetics Corp',
      location: 'Spokane, WA',
      duration: '2004 to 2008',
      details: {  project: 'Neokinetics',
                  text:   [ "Developed security system for eBay to identify users by typing patterns."
                            ,"Engineered cross-platform software: Mac OSX, Windows, Linux."
                            ,"Enterprise reporting development and data mining in MySQL for eBay."
                            ,"Built 3D data mining visualization tool using Crystal Space engine." 
                          ],
                 imageSrc: [  'https://erikwhutchinson.com/assets/images/devonthink_1.jpg',
                              'https://erikwhutchinson.com/assets/images/devonthink_2.png',
                              'https://erikwhutchinson.com/assets/images/devonthink_3.jpg',
                              'https://erikwhutchinson.com/assets/images/devonthink_4.jpg',
                              'https://erikwhutchinson.com/assets/images/ebay_1.png',
                              'https://erikwhutchinson.com/assets/images/ebay_2.png',
                              'https://erikwhutchinson.com/assets/images/source_code_example_1.png',
                              'https://erikwhutchinson.com/assets/images/source_code_example_2.png',
                              'https://erikwhutchinson.com/assets/images/source_code_example_3.png',
                         ],
                },
    },

 
    {
      companyName: 'Spectre AI / Next IT',
      location: 'Spokane, WA',
      url: 'https://amphenol-ns.com/',
      duration: '2003 to 2004',
      details: {  project: 'SpectreAI',
                  text:   [ "Programmed security tools for various government agencies."
                            ,"Developed client/server apps for government security communications."
                            ,"Built autonomous agents to sift large collections of data and send alerts."
                          ],
                 imageSrc: [  'https://erikwhutchinson.com/assets/images/askJenn_1.png',
                              'https://erikwhutchinson.com/assets/images/askJenn_2.png',
                              'https://erikwhutchinson.com/assets/images/askJenn_3.png',
                              'https://erikwhutchinson.com/assets/images/askSpike_1.png',
                              'https://erikwhutchinson.com/assets/images/askSpike_2.png',
                              'https://erikwhutchinson.com/assets/images/sgtStar_iPhone_1.png',
                              'https://erikwhutchinson.com/assets/images/sgtStar_iPhone_2.png',
                              'https://erikwhutchinson.com/assets/images/sgtStar_iPhone_3.png',
                              'https://erikwhutchinson.com/assets/images/sgtStar_web_1.png',
                              'https://erikwhutchinson.com/assets/images/sgtStar_web_2.png',
                            ],
                  urls: [{url: 'https://www.nextit.com/', 
                          alt: 'Spectre AI / Next IT'}]
                },
    },

 
    {
      companyName: 'Sennari Interactive',
      location: 'Los Angeles, CA',
      duration: '2002',
      details: {  project: 'Sennari',
                  text:   [ "Programmer for multiple titles for the Game Boy Advance and GameCube"
                            ,"Designed and planned out gameplay specifics for console sports titles."
                            ,"Engineer on team that developed first commerical mobile 3D graphics engine"
                            ,"Led programming team in development of Game Boy Advance sports title."
                          ],
                 imageSrc: [  'https://erikwhutchinson.com/assets/images/Driver2_box.jpg',
                              'https://erikwhutchinson.com/assets/images/DriverSequence.gif',
                              'https://erikwhutchinson.com/assets/images/driver2_2.jpg',
                              'https://erikwhutchinson.com/assets/images/driver2_3.jpg',
                              'https://erikwhutchinson.com/assets/images/driver2_4.jpg',
                              'https://erikwhutchinson.com/assets/images/ppg1.jpg',
                              'https://erikwhutchinson.com/assets/images/ppg2.jpg',
                              'https://erikwhutchinson.com/assets/images/ppg3.jpg',
                              'https://erikwhutchinson.com/assets/images/ppg4.jpg',
                            ],
                },
    },

 
    {
      companyName: 'Interactive Imagination',
      location: 'Seattle, WA',
      duration: '2000 to 2001',
      details: {  project: 'II',
                  text:   [ "Programmer for two published titles: Magi-Nation and Keeper’s Quest."
                            ,"Developed interactive game engines in Assembly for the Game Boy Color."
                            ,"Led programming team in development of Keeper’s Quest game."
                          ],
                 imageSrc: [  'https://erikwhutchinson.com/assets/images/mnd_box.jpg',
                              'https://erikwhutchinson.com/assets/images/keeper_1.gif',
                              'https://erikwhutchinson.com/assets/images/keeper_2.gif',
                              'https://erikwhutchinson.com/assets/images/mnkq_box.png',
                              'https://erikwhutchinson.com/assets/images/magination_2.gif',
                              'https://erikwhutchinson.com/assets/images/magination_4.gif',
                              'https://erikwhutchinson.com/assets/images/magination_5.gif',
                              'https://erikwhutchinson.com/assets/images/magi_nation1.jpg',
                              'https://erikwhutchinson.com/assets/images/assembly_source_example_1.png',
                              'https://erikwhutchinson.com/assets/images/assembly_source_example_1.png',                              
                            ],
                },
    },

 
    {
      companyName: 'NIOSH Spokane Research Labs',
      location: 'Spokane, WA',
      duration: '1999 to 2000',
      details: {  project: 'NIOSH',
                  text:   [ "Developed graphical simulator to assist in employee safety training."
                            ,"Researched newest virtual reality technology and multi-user capabilities."
                            ,"Programmed in C++, TCL\TK, and DirectX under Windows."
                          ],
                 imageSrc: [  'https://erikwhutchinson.com/assets/images/Mine_Simulator.jpg',
                              'https://erikwhutchinson.com/assets/images/mine_sim1.jpg',
                              'https://erikwhutchinson.com/assets/images/mine_sim2.jpg',
                              'https://erikwhutchinson.com/assets/images/mine_sim3.jpg',
                            ],
                },
    },
 
 
    {
      companyName: 'Gonzaga University Bachelor of Science in Computer Science',
      location: 'Spokane, WA',
      url: 'https://gonzaga.edu/',
      duration: '1997 to 2000',
    },
 
 
    {
      companyName: 'Roger Williams University Double Major in Math and Computer Science',
      location: 'Spokane, WA',
      url: 'https://www.rwu.edu/',
      duration: '1995 to 1997',
    },
  
    {
      companyName: 'Languages / Frameworks: Javascript',
      location: '',
      duration: '2001 to Present',
      inProgress: true,
    },

    {
      companyName: 'Languages / Frameworks: React (ES6, Babel, Webpack)',
      location: '',
      url: 'https://reactjs.org/',
      duration: '2015 to Present',
      inProgress: true,
    },
 
    {
      companyName: 'Languages / Frameworks: Node.js',
      location: '',
      url: 'https://nodejs.org/',
      duration: '2014 to Present',
      inProgress: true,
    },

    {
      companyName: 'Languages / Frameworks: Ruby',
      location: '',
      url: 'https://www.ruby-lang.org/',
      duration: '2008 to Present',
      inProgress: true,
    },
 
    {
      companyName: 'Languages / Frameworks: Ruby on Rails',
      location: '',
      url: 'https://rubyonrails.org/',
      duration: '2008 to Present',
      inProgress: true,
    },

    {
      companyName: 'Languages / Frameworks: C++ / C',
      location: '',
      url: 'https://isocpp.org/',
      duration: '1997 to Present',
      inProgress: true,
    },
  
  
    {
      companyName: 'Languages / Frameworks: Assembly',
      location: '',
      duration: '1997 to 2004',
    },
  
    {
      companyName: 'Languages / Frameworks: Java',
      location: '',
      duration: '1997 to 2017',
    },
  
    {
      companyName: 'Languages / Frameworks: C#',
      location: '',
      duration: '2003 to 2017',
    },
  
    {
      companyName: 'Languages / Frameworks: ASP.NET',
      location: '',
      url: 'https://dotnet.microsoft.com/apps/aspnet',
      duration: '2006 to Present',
      inProgress: true,
    },

    {
      companyName: 'Languages / Frameworks: Python',
      location: '',
      url: 'https://www.python.org/',
      duration: '2017 to Present',
      inProgress: true,
    },

    {
      companyName: 'Languages / Frameworks: SQL',
      location: '',
      duration: '2000 to Present',
      inProgress: true,
    },

    {
      companyName: 'Languages / Frameworks: Objective-C',
      location: '',
      duration: '2004 to 2018',
    },

 
    {
      companyName: 'Languages / Frameworks: Ember.js',
      location: '',
      url: 'https://reactjs.org/',
      duration: '2016 to 2017',
    },
  
    {
      companyName: 'Languages / Frameworks: Smalltalk',
      location: '',
      duration: '1995 to 2002',
    },
  

    {
      companyName: 'Systems: AWS',
      location: '',
      inProgress: true,
      duration: '2018 to Present',
    },
  
    {
      companyName: 'Systems: Windows',
      location: '',
      inProgress: true,
      duration: '1995 to Present',
    },
  
    {
      companyName: 'Systems: Linux',
      location: '',
      inProgress: true,
      duration: '1997 to Present',
    },
  
    {
      companyName: 'Systems: OSX / MacOS',
      location: '',
      inProgress: true,
      duration: '2004 to Present',
    },
  
    {
      companyName: 'Systems: IOS / iPhone OS',
      location: '',
      inProgress: true,
      duration: '2007 to Present',
    },
  
    {
      companyName: 'Systems: Android',
      location: '',
      duration: '2009 to 2015',
    },
  

  ];

  return retData
}


export function getPortfolioProjectsInfo()
{
  let retData = [ 
    {
      project: 'Umpqua Treasury Management Suite',
      system: 'React, Javascript, Ember.js, C#, SQL, JSON, Windows',
      details: {  project: 'Umpqua',
                  text:   [  "Built out treasury management portal for bank processing." 
                            ,"Worked directly with Treasury Management to meet customer requirements."
                            ,"Developed in multiple Javascript frameworks, C#, and Sql Server."
                          ],
                 imageSrc: [  'https://erikwhutchinson.com/assets/images/umpqua_bank_logo.png',
                            ],
                  urls: [{url: 'https://www.umpquabank.com/', 
                          alt: 'Umpqua Bank'}]
                },
    },

    {
      project: 'PayTrace Hosted Payment Fields',
      system: 'React, Ruby on Rails, Javascript, ASP.NET, SQL, JSON, Windows, Linux, MacOS',
      inProgress: true,
    },

    {
      project: 'PayTrace Payment Link',
      system: 'React, Ruby on Rails, Javascript, ASP.NET, SQL, JSON, Windows, Linux, MacOS',
      inProgress: true,
    },

    {
      project: 'PayTrace Architecture Design & Implemntation',
      system: 'React, Ruby on Rails, Javascript, Python, ASP.NET, SQL, AWS, JSON, Windows, Linux, MacOS',
      inProgress: true,
    },


    {
      project: 'PayTrace Legacy Project Migration',
      system: 'React, Ruby on Rails, Javascript, Python, ASP.NET, SQL, AWS, JSON, Windows, Linux, MacOS',
      inProgress: true,
    },

    {
      project: 'Kaiser Permanente Baby Mobile App',
      system: 'Objective-C, C#, Javascript, JSON, YAML, SQL, Windows, IOS, Android, OSX',
      inProgress: true,
    },

    {
      project: 'COPAXONE Injection Tracker Mobile App',
      system: 'Objective-C, C#, Javascript, JSON, YAML, SQL, Windows, IOS, Android, OSX',
      inProgress: true,
    },

    {
      project: 'Sgt Star, Army Recruitment Assistant',
      system: 'C++, C#, Javascript, Objective-C, SQL, Windows',
      url: 'https://www.youtube.com/watch?v=HgI7ptGBqJ4',
      details: {  project: 'SgtStar',
                  text:   [  "Created mobile and web applications for the Army to aid in recruitment." 
                            ,"One of the original virtual chatbots, the Army introduced Sgt Star to improve costs and to handle vastly increased Army recruitment interest in the early 2000s."
                            ,"Core engine written in C++ with a SQL Server backend."
                            ,"Various frontend interfaces written in C#, Javascript, and Objective-C"
                          ],
                imageSrc: [   'https://erikwhutchinson.com/assets/images/sgtStar_iPhone_1.png',
                              'https://erikwhutchinson.com/assets/images/sgtStar_iPhone_2.png',
                              'https://erikwhutchinson.com/assets/images/sgtStar_iPhone_3.png',
                              'https://erikwhutchinson.com/assets/images/sgtStar_web_1.png',
                              'https://erikwhutchinson.com/assets/images/sgtStar_web_2.png',
                            ],
                urls: [{url: 'https://www.youtube.com/watch?v=HgI7ptGBqJ4', 
                          alt: 'Sgt Star Video Review'}
                        ,{url: 'https://en.wikipedia.org/wiki/SGT_STAR', 
                          alt: 'Sgt Star Wikipedia Entry'}

                      ]
                } 
    },

    {
      project: 'Telecom Tycoon',
      system: 'C++, Objective-C, IOS, OSX, Linux',
      url: 'https://www.cultofmac.com/38853/telecom-tycoon-hd-lets-you-roll-out-atts-3g-network-for-them/',
      details: {  project: 'Telecom Tycoon',
                  text: [ 'C++, Objective-C, YAML',
                         'Custom in-house scripting engine for level development',
                         'Isometric opengl graphics engine',
                         'For iPhone and iPad launch day app'
                        ],
                 imageSrc: ['https://erikwhutchinson.com/assets/images/telecom_tycoon_1.jpg',
                              'https://erikwhutchinson.com/assets/images/telecom_tycoon_2.jpg',
                              'https://erikwhutchinson.com/assets/images/telecom_tycoon_3.jpg',
                              'https://erikwhutchinson.com/assets/images/telecom_tycoon_4.jpg',
                            ],
                  urls: [{url: 'https://www.cultofmac.com/38853/telecom-tycoon-hd-lets-you-roll-out-atts-3g-network-for-them/', 
                          alt: 'Telecom Tycoon Review'}]
                },

    },


    {
      project: 'Merrill Lynch Pilot Project',
      system: 'Objective-C, C#, Javascript, JSON, YAML, SQL, Windows, IOS, Android, OSX',
    },

    {
      project: 'eBay Security System User Identification ',
      system: 'Patent 8,843,754, Objective-C, C++, C#, Javascript, JSON, SQL, Oracle, Windows, OSX, Linux',
      details: {  project: 'Neokinetics',
                  text:   [ "Developed security system for eBay to uniquely identify users based solely typing patterns."
                            ,"eBay needed a system to better detect fraudulent access to accounts",
                            ,"Implemented as part of the eBay login stack on the main ebay.com landing page",
                            ,"Awarded patent 8,843,754 for work on user identification topic."
                          ],
                 imageSrc: [  'https://erikwhutchinson.com/assets/images/ebay_1.png',
                              'https://erikwhutchinson.com/assets/images/ebay_2.png',
                          ],
                  urls: [{url: 'https://patents.google.com/patent/US8843754', 
                          alt: 'Patent 8,843,754'}]
                },
    },

    {
      project: 'Cauzal Coffee',
      url: 'https://www.kineticbranding.com/cauzal-coffee.html',
      system: 'Flash, Javascript',
      details: {  project: 'Cauzal',
                  text:   [ "Developed e-commerce store for charity focused organization."
                          ],
                 imageSrc: [  'https://erikwhutchinson.com/assets/images/cauzal_coffee.jpg',
                              'https://erikwhutchinson.com/assets/images/cauzal_coffee_1.png',
                              'https://erikwhutchinson.com/assets/images/cauzal_coffee_2.jpg',
                              'https://erikwhutchinson.com/assets/images/cauzal_coffee_3.jpg',
                              'https://erikwhutchinson.com/assets/images/cauzal_coffee_4.png',
                              'https://erikwhutchinson.com/assets/images/cauzal_coffee_5.png',
                          ],
                  urls: [{url: 'https://www.kineticbranding.com/cauzal-coffee.html', 
                          alt: 'Cauzal Coffee'}]
                }
    },


    {
      project: 'NIOSH VR Mine Simulator',
      system: 'C++, DirectX, TCL\\TK, Windows',
      details: {  project: 'NIOSH',
                  text:   [ "Graphical simulator to assist in employee safety training as part of OSHA / NIOSH's core objective."
                            ,"Researched newest (1999) virtual reality technology and multi-user capabilities to allow for collaborative online training in real world modeled virtual underground mines."
                            ,"Developed in C++, TCL\TK, and DirectX under Windows."
                          ],
                 imageSrc: [  'https://erikwhutchinson.com/assets/images/Mine_Simulator.jpg',
                              'https://erikwhutchinson.com/assets/images/mine_sim1.jpg',
                              'https://erikwhutchinson.com/assets/images/mine_sim2.jpg',
                              'https://erikwhutchinson.com/assets/images/mine_sim3.jpg',
                            ],
                },
    },


    {
      project: 'Driver 2 Advane GBA',
      system: 'C, C++, Game Boy Advance',
      details: {  project: 'Sennari',
                  text:   [ "Ground up Game Boy Advance rewrite/port of Driver 2 for the Sony PlayStation"
                            ,"Team developed first commercial mobile 3D graphics engine in C++ available on Game Boy Advance"
                          ],
                 imageSrc: [  'https://erikwhutchinson.com/assets/images/Driver2_box.jpg',
                              'https://erikwhutchinson.com/assets/images/DriverSequence.gif',
                              'https://erikwhutchinson.com/assets/images/driver2_2.jpg',
                              'https://erikwhutchinson.com/assets/images/driver2_3.jpg',
                              'https://erikwhutchinson.com/assets/images/driver2_4.jpg',
                            ],
                  urls: [{url: 'https://en.wikipedia.org/wiki/Driver_2', 
                          alt: 'Driver 2 Wikipedia Entry'},
                        {url: 'https://www.youtube.com/watch?v=AdzE63IAscg', 
                          alt: 'Driver 2 Advance Gameplay Video'},
                        {url: 'https://www.gamespot.com/reviews/driver-2-advance-review/1900-2895730/', 
                          alt: 'Driver 2 Advance Review'}

                          ]
                }
    },

   {
      project: 'Magi-Nation GBC',
      system: 'Z80 Assembly, C, Game Boy Color',
      inProgress: true,
    },

   {
      project: 'Magi-Nation: Keeper’s Quest GBC',
      system: 'Z80 Assembly, C, Game Boy Color',
      inProgress: true,
    },


   {
      project: 'The Powerpuff Girls',
      system: 'C, C++, GameCube',
      inProgress: true,
    },

    {
      project: 'Little League Baseball 2002',
      system: 'C++, Game Boy Advance',
      inProgress: true,
    },


    {
      project: 'Top Chef: Foodie Fight',
      system: 'C++, Java, Objective-C, Android, IOS',
      details: {  project: 'FoodieFight',
                  text:   [  "Worked with the Bravo tv network to develop Android/IOS game based on the Top Chef IP." 
                           ],
                 imageSrc: [  'https://erikwhutchinson.com/assets/images/top-chef-foodie-fight_1.jpg',
                              'https://erikwhutchinson.com/assets/images/top-chef-foodie-fight_2.jpg',
                              'https://erikwhutchinson.com/assets/images/top-chef-foodie-fight_3.jpg',
                              'https://erikwhutchinson.com/assets/images/top-chef-foodie-fight_4.jpg',
                         ],
                },
    },

    {
      project: 'Isoemtric Multiplatform Game Engine',
      system: 'C++, YAML, JSON, OpenGL, DirectX, IOS, OSX, Windows, Linux',
      inProgress: true,
    },

    {
      project: 'iBrawl',
      system: 'C++, Objective-C, SQL, YAML, JSON, IOS',
      details: {  project: 'Gourami',
                  text:   [  "Early IOS gaming title developed for the mobile space.",
                              "Matchmaking service supported by a C++ and MySql backend.",
                              "Core engine written in C++ with an interface written in Objective-C.",
                              "JSON and YAML facilitated client side match making and challenge resolution." 
                          ],
                 imageSrc: [  'https://erikwhutchinson.com/assets/images/ibrawl_screensplash.jpg',
                              'https://erikwhutchinson.com/assets/images/ibrawl_screenfight.jpg',
                              'https://erikwhutchinson.com/assets/images/ibrawl_screenchallenge.jpg',                              
                           ],
                },
    },

    {
      project: 'Telect.com e-commerce site',
      system: 'C++, C#, Javascript, SQL, JSON, Windows, OSX',
      url: 'https://amphenol-ns.com/',
      details: {  project: 'Telect',
                  text:   [  "Developed the external facing e-commerce website Telect.com." 
                            ,"Developed in C/C++, Objective-C, Javascript, ASP.NET, and DotNetNuke."
                          ],
                 imageSrc: [  'https://erikwhutchinson.com/assets/images/telect_website_1.png',
                              'https://erikwhutchinson.com/assets/images/telect_website_5.png',
                              'https://erikwhutchinson.com/assets/images/telect_website_3.png',
                              'https://erikwhutchinson.com/assets/images/telect_website_4.png',
                              'https://erikwhutchinson.com/assets/images/telect_website_2.png',
                            ],
                  urls: [{url: 'https://amphenol-ns.com/', 
                          alt: 'Telect'}]
                },
    },


    {
      project: 'DEVONthink',
      system: 'Objective-C, C++, Javascript, JSON, YAML, OSX, Linux, Windows',
      inProgress: true,
    },

    {
      project: 'DEVONagent',
      system: 'Objective-C, C++, Javascript, JSON, YAML, OSX, Linux, Windows',
      inProgress: true,
    },

    {
      project: 'Crystal Space 3D Data Mining Visualiztion Tool',
      system: 'Objective-C, C++, Javascript, JSON, YAML, OSX, Linux, Windows',
      inProgress: true,
    },







 
    {
      project: 'Fanboy & Chum Chum: Frosty Freeze Ball Fight',
      system: 'Nickelodeon Contract, Flash, Javascript',
      details: {  project: 'Fanboy',
                  text:   [  "Worked with Nickelodeon to create a Javascript/Flash game for their web portal baded on the Fanboy & Chum Chum IP." 
                          ],
                 imageSrc: [  'https://erikwhutchinson.com/assets/images/fanboy_1.png',
                              'https://erikwhutchinson.com/assets/images/fanboy_2.png',
                         ],
                },
    },

 
  ];

  return retData
}



