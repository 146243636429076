// SideBar
//
//  desc:
//         
//
//
//  props:
//

import React from 'react';
import './index.css';

import SessionCard from '../SessionCard';
import UserList from '../UserList';

class SideBar extends React.Component
{
 constructor(props)
  {
    super(props)
    this.state = {

    };
  }


  render()
  {
    return(
      <div className='sidebar_div'>
        <div className='sidebar_top_spacer' />
        <SessionCard />
        <div className='sidebar_separator' />
        <UserList />
      </div>
    );
  }

}
 
export default SideBar;
