// DetailsCard
//
//  desc:
//         
//
//
//  props:
//    details:
//    outsideClickedHandler:              callback handler for when a click event occurs outside this card 

import React from 'react';
import {List, Card, CardImg, CardText, CardBody, CardTitle, CardSubtitle, Button} from 'reactstrap';
import './index.css';

class DetailsCard extends React.Component
{
 constructor(props)
  {
    super(props)
    this.state = {
      imageIndex: 0,
    };

    this.wrapperRef = React.createRef();
//    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.timerElapsed = this.timerElapsed.bind(this);

  }

  componentDidMount() 
  {
      document.addEventListener('mousedown', this.handleClickOutside);

      var intervalId = setInterval(this.timerElapsed, 2000);
      // store intervalId in the state so it can be accessed later:
      this.setState({intervalId: intervalId});
  }


  componentWillUnmount() 
  {
      document.removeEventListener('mousedown', this.handleClickOutside);
      clearInterval(this.state.intervalId);

  }

  timerElapsed()
  {
    let newImageIndex = this.state.imageIndex + 1
    if (newImageIndex > this.props.details.imageSrc.length - 1)
    {
      newImageIndex = 0;
    }
    this.setState({imageIndex: newImageIndex});     
  }


  /**
   * Alert if clicked on outside of element
   */
  handleClickOutside(event) 
  {
    if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) 
    { 
        if (this.props.outsideClickedHandler)
        {
          this.props.outsideClickedHandler();
        }
    }
  }


  renderCardImage()
  {
    let rendered 


    return rendered
  }


  renderURL()
  {
    let rendered

    let urlList = []
    let props = this.props
    if (this.props.details.urls && this.props.details.urls.length > 0)
    {
      this.props.details.urls.forEach(function(urlInfo, index)
      {
        let key = props.details.project + "_" + urlInfo.alt + "_" + index;
        let tempUrl = <li key={key}> <a href={urlInfo.url} target="_blank">{urlInfo.alt} </a> </li>
        urlList.push(tempUrl)
      });

      rendered = <List> {urlList} </List>
    }
    return urlList
  }


  renderCardText()
  {
    let rendered

    let textList = []
    let props = this.props
    this.props.details.text.forEach(function(text, index)
    {
      let key = props.details.project + "_text_" + index;
      let tempText = <li key={key}>{text}</li>
      textList.push(tempText)
    });

    rendered = <List> {textList} </List>
    return rendered
  }


  render()
  {
    let imageSrc = this.props.details.imageSrc[this.state.imageIndex]        //TODO: rotate through the images if there are multiple images available
    let altText

    let text = this.renderCardText();
    let urls = this.renderURL();

    return(
      <div className='details_card' ref={this.wrapperRef}>
        <Card>
          <CardImg top width="100%"src={imageSrc} alt='' />
          <CardSubtitle tag="h6" className="mb-2 text-muted">{urls}</CardSubtitle>
          <CardBody>
            {text}
          </CardBody>
        </Card>
      </div>
    );
  }

}

export default DetailsCard;
