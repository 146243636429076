import React, { Component } from 'react';

import './index.css';
import { Row, Col } from 'reactstrap';

import Sidebar from '../SideBar';
import Container from '../Container';


class Body extends Component {
  constructor(props)
  {
    super(props);

 
  }



  componentDidMount() 
  {

  }

  
  render() {

    return (
      <div className="odieworks_body">
        <Row>
          <Col className='odieworks_left_col' sm='3'>
            <Sidebar />
          </Col>

          <Col sm='9'>
            <Container {...this.props} />
          </Col>

        </Row>      
       
      </div>
    );
  }
}

export default Body;
