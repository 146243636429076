//import {callToEndpoint} from '../../templates/lib/comms/commsHelper.js';        //callToEndpoint performs the actual network communications 


// endpoints for api calls should be defined here for use by calling functions
//    endpoint is a hash that contains the endpoint path and the endpoint type
//              path should be of the type "/index.html" 
//              type should be either "GET" or "POST"
//            example const ENDPOINT_GET_SOME_INFO = {path: '/info/path', type: "GET"}


const ENDPOINT_GET_POSITION_INFO = {path: '/position', type: "GET"}


export function endpointCallGetPositionInfo(jsonData)
{
  return new Promise(function(resolve, reject) {
    callToEndpoint(ENDPOINT_GET_POSITION_INFO, jsonData, function(data) {
      if (data.status == 200) {
        resolve(data);
      } else {
        reject("network error");
      }
    }, null, process.env.PORTFOLIO_API_URL);
  });
}
