// UserList
//
//  desc:
//         
//
//
//  props:
//

import React from 'react';
import { Row, Col } from 'reactstrap';

import './index.css';

class UserList extends React.Component
{
 constructor(props)
  {
    super(props)
    this.state = {

    };
  }


  render()
  {
    return(
      <div className='user_list'>


          <Row className='user_list_row'>
            <Col sm='1' className='user_list_icon_col'>
              <i className="fas fa-circle user_list_icon"></i>
            </Col>
            <Col sm='8' className='user_list_name'>
              Erik W Hutchinson
            </Col>
          </Row>

        
        <div className='user_list_separator' />
      </div>
    );
  }

}

export default UserList;
